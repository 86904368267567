<template>
  <div>
    <div class="text-header mb-10">Log In</div>
    <v-text-field
      label="Enter your email address or mobile number"
      rounded
      filled
      color="grey"
      class="py-0 my-0"
      v-model="emailOrPhoneNumber"
      hide-details
      dense
    ></v-text-field>

    <v-row justify="center" class="my-1">
      <div id="recaptcha-container"></div>
    </v-row>

    <v-btn
      class="sign-up-button my-4 white--text"
      rounded
      color="#00b08d"
      large
      block
      :disabled="!emailOrPhoneNumber || isLoading"
      :loading="isLoading"
      @click="nextSignInStep()"
    >
      Continue
    </v-btn>
    <div class="text-center my-3" @click="forgotPassword">
      <span class="text-underline forgot-password-link pointer">
        Forgotten your password?
      </span>
    </div>

    <v-row align="center" class="divider">
      <v-col><hr /></v-col>
      <v-col cols="auto"> OR </v-col>
      <v-col><hr /></v-col>
    </v-row>

    <!-- facebook -->
    <v-btn
      class="sign-up-button my-4"
      dark
      rounded
      large
      block
      color="#1877F2"
      :loading="isLoading"
      :disabled="isLoading"
      @click="signInWithFacebook"
    >
      <v-icon class="mr-2"> mdi-facebook </v-icon>
      Continue with Facebook</v-btn
    >
    <!-- google -->
    <v-btn
      class="sign-up-button my-4"
      color="black"
      outlined
      dark
      rounded
      large
      block
      @click="signInWithGoogle"
      :loading="isLoading"
      :disabled="isLoading"
    >
      <v-row align="center" justify="center">
        <v-col cols="auto">
          <v-img
            contain
            height="16"
            width="16"
            class="pa-0 ma-0"
            :src="require('@/assets/google-icon.svg')"
          />
        </v-col>
        Continue with Google
      </v-row>
    </v-btn>
    <v-btn
      class="sign-up-button my-4"
      dark
      large
      block
      rounded
      :loading="isLoading"
      :disabled="isLoading"
      @click="signInWithApple"
    >
      <v-icon class="mr-2"> mdi-apple </v-icon>
      Continue with Apple</v-btn
    >
    <div
      class="text-center text-description my-6"
      @click="$router.push({ name: 'SignUp' })"
    >
      <span>Don’t have an account?</span>
      <span class="text-underline pointer"> Sign up here </span>
    </div>
  </div>
</template>

<script>
import AuthMixin from "@/mixins/AuthMixin.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { validateEmail } from "@/utilities/validateEmail.js";
export default {
  created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Sign In");
    if (this.getCurrentUser) {
      this.$router.push({ name: "Onboarding" });
    }
    this.initReCaptcha();
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mixins: [AuthMixin],
  computed: {
    ...mapGetters(["getCurrentUser"]),
    isEmail() {
      return this.emailOrPhoneNumber?.includes("@");
    },
    emailOrPhoneNumber: {
      get: function () {
        return this.$store.getters["getEmailOrPhoneNumber"];
      },
      set: function (newVal) {
        this.$store.commit("setEmailOrPhoneNumber", newVal);
      },
    },
  },
  methods: {
    ...mapActions([
      "signUpOrSignInUsingGoogleProvider",
      "signUpOrSignInUsingFacebookProvider",
      "signUpOrSignInUsingAppleProvider",
      "signUpOrSignInToFirebaseByPhoneNumber",
      "sendForgotPasswordEmail",
      "initReCaptcha",
    ]),
    ...mapMutations(["setErrorSnackbarMessage", "setCurrentAppBarTitle"]),
    async forgotPassword() {
      if (!validateEmail(this.emailOrPhoneNumber)) {

        await this.setErrorSnackbarMessage("Invalid email");
      } else {
        this.sendForgotPasswordEmail(this.emailOrPhoneNumber);
      }
    },
    nextSignInStep() {
      if (this.isEmail) {
        this.$router.push({ name: "EmailSignIn" });
      } else {
        this.signInWithPhoneNumber();
      }
    },
    async signInWithPhoneNumber() {
      this.isLoading = true;
      try {
        await this.signUpOrSignInToFirebaseByPhoneNumber();
        this.$router.push({ name: "PhoneSignUp" });
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async signInWithGoogle() {
      this.isLoading = true;
      try {
        await this.signUpOrSignInUsingGoogleProvider();
        await this.afterSignIn();
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async signInWithFacebook() {
      this.isLoading = true;
      try {
        await this.signUpOrSignInUsingFacebookProvider();
        await this.afterSignIn();
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async signInWithApple() {
      this.isLoading = true;
      try {
        await this.signUpOrSignInUsingAppleProvider();
        await this.afterSignIn();
      } catch (error) {
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.divider > div > hr {
  height: 0.5px;
  background-color: #333333;
  border: 0px;
}
.divider {
  margin: 24px 0px;
  font-family: "League Spartan", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.forgot-password-link {
  font-family: "League Spartan", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
}
</style>